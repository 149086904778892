import { BaseWidget } from './base-widget';

export class Faq extends BaseWidget {
    // public static readonly NAME = 'faq';
    static NAME = 'faq';
    // private faqButton: JQuery;
    // private faqContent: JQuery;
    // private height: number;

    constructor(element) {
        super(element);
        this.faqButton = $('[data-faq-button]', this.element);
        this.faqContent = $('[data-faq-content]', this.element);
    }

    init() {

        this.element.addClass('is-hidden');

        this.faqButton.on('click', (e) => {
            this.toggle();
        });
    }

    toggle() {
        if (this.element.hasClass('is-hidden')) {
            this.element.removeClass('is-hidden');
            this.element.addClass('is-open');
        } else {
            this.element.addClass('is-hidden');
            this.element.removeClass('is-open');
        }
    }
}
