import { PopoverService } from './helpers/popover-service';

import { BaseWidget } from './base-widget';

export class Video extends BaseWidget {
    static NAME = 'video';

    constructor(element) {
        super(element);

        this.youtubeId = this.element.data('youtubeid');
        this.playElem = $('[data-video-play]', this.element);
    }

    init() {
        if (!this.youtubeId) {
            console.error('no youtube-id set for video');
            return;
        }

        this.loadYoutubeApiScript();

        this.playElem.on('click', e => {
           this.play();
        });
    }

    play() {
        const html = '<div class="media media--video"><div></div></div>';

        const options = {
            closeable: true,
            cssClass: false,
            onInsert: (overlay) => {
                this.youtubePlayer = this.createYoutubePlayer(overlay);
            },
            onClose: (overlay) => {
                this.close(overlay);
                return false;
            }
        };

        PopoverService.spawnOverlay(html, options);
    }

    close(overlay) {
        $('.media--video', overlay).removeClass('is-loaded');

        requestAnimationFrame(() => {
            window.setTimeout(() => {
                this.destroyYoutubePlayer();
            }, 200);
        });
    }

    createYoutubePlayer(overlay) {
        const self = this; // save this because of context YTPlayer
        const videoElem = $('.media > div', overlay)[0];

        return new YT.Player(videoElem, {
            videoId: self.youtubeId,
            playerVars: self.playerVars,
            height : '512',
            width : '100%',
            events: {
                'onReady': (e) => {
                    e.target.playVideo();
                    $('.media--video', overlay).addClass('is-loaded');
                },
                'onStateChange': (e) => {
                    if (e.data === 0) {
                        self.close(overlay);
                    }
                }
            }
        });
    }

    destroyYoutubePlayer() {
        if (this.youtubePlayer) {
            this.youtubePlayer.destroy();
            this.youtubePlayer = undefined;
        }
    }

    loadYoutubeApiScript() {
        if (!$('script[src="' + window.location.protocol + '//www.youtube.com/iframe_api"]').length) {
            const tag = document.createElement('script');
            tag.src = window.location.protocol + '//www.youtube.com/iframe_api';
            const firstScriptTag = document.getElementsByTagName('script')[0];
            firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
        }
    }
}
