import 'slick-carousel';
import { BaseWidget } from './base-widget';

export class Carousel extends BaseWidget {
    static NAME = 'carousel';

    constructor(element) {
        super(element);

        this.inner = $('[data-carousel-inner]', this.element);
        this.wrapper = $(`[${this.element.data('wrapper')}]`);
        this.arrows = $('[data-carousel-arrows]', this.element);
        this.dots = $('[data-carousel-dots]', this.element);
        this.counter = $('[data-carousel-counter]', this.element);
        this.nav = this.element.data('nav');
        this.fade = this.element.data('fade');
        this.centerMode = this.element.data('center-mode');
        this.infinite = this.element.data('infinite');
        this.autoplay = this.element.data('autoplay');
        this.progress = this.element.data('progress');
        this.timeout = this.element.data('timeout');
        this.variableWidth = this.element.data('variable-width');
        this.adaptiveHeight = this.element.data('adaptive-height');
        this.amount = this.element.data('amount');
        this.id = this.element.data('id');

        let totalSlider = 0;
        
        if (this.counter) {
            this.inner.on('init', function(event, slick) {
                $('[data-carousel-counter]').text(parseInt(slick.currentSlide + 1));
                $('.carousel__amount--index').text(parseInt(slick.currentSlide + 1));
            });

            this.inner.on('afterChange', function(event, slick, currentSlide) {
                // $('[data-carousel-counter]').text(parseInt(slick.currentSlide + 1) + ' / ' + slick.slideCount);
                // $('[data-carousel-counter]').text(parseInt(slick.currentSlide + 1));
                $('.carousel__amount--index', this.wrapper).text(parseInt(slick.currentSlide + 1));
            });
        }

        if (this.arrows) {
            this.showArrows = true;
        }

        if (this.nav) {
            this.nav = `.carousel--${this.id}-${this.nav} > .carousel__inner`;
        } else {
            this.nav = false;
        }
    }

    init() {
        // this.inner.on('init', function(event, slick, currentSlide, nextSlide) {
        //     const slideCount = slick.slideCount - 1;

        //     // this.target = $(`[data-slick-index="${slideCount}"]`);
        //     // this.target.addClass('is-last');
        //     this.totalSlider = slick.slideCount;
        //     $('.carousel__amount--total').text(slick.slideCount);

        //     console.log(slick.slideCount);
        //     console.log(slick.currentSlide+1);
        // });

        this.inner.slick({
            infinite: false,
            slidesToShow: 1,
            slidesToScroll: 1,
            mobileFirst: true,
            arrows: this.arrows ? true : false,
            appendArrows: this.arrows,
            dots: this.dots ? true : false,
            appendDots: this.dots,
            draggable: true,
            asNavFor: this.nav,
            fade: this.fade ? true : false,
            cssEase: 'linear',
            lazyLoad: this.lazyload,
            centerMode: this.centerMode ? true : false,
            centerPadding: this.margin,
            focusOnSelect: true,
            infinite: this.infinite ? true : false,
            adaptiveHeight : this.adaptiveHeight ? true : false,
            variableWidth: this.variableWidth ? true : false,
            // pauseOnDotsHover: this.pauseOnHover ? true : false,
            autoplay: this.autoplay ? true : false,
            autoplaySpeed: this.timeout,
            speed: 100,
            // prevArrow: `
            //     <button class="carousel__arrow carousel__arrow--prev">
            //         <span class="carousel__arrow-text">Previous</span>
            //         <span class="carousel__index"></span>
            //     </button>`,
            // nextArrow: `
            //     <button class="carousel__arrow carousel__arrow--next">
            //         <span class="carousel__arrow-text">Next</span>
            //         <span class="carousel__total"></span>
            //     </button>`,
            prevArrow: $('.carousel__arrow--prev', this.wrapper),
            nextArrow: $('.carousel__arrow--next', this.wrapper),
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: this.amount ? this.amount : 3,
                    }
				},
				{
					breakpoint: 1280,
					settings: {
						slidesToShow: this.amount ? this.amount : 5,
					}
				}
			
            ]
        });

        if (this.progress) {
            this.progressbar()
        }
    }

    progressbar() {
        const bar = $('[data-carousel-progress]', this.wrapper);
        let isPause = false;
        let time = 14;
        let tick = 0;
        let percentTime = 0;
            
        this.element.on({
            mouseenter: function() {
                isPause = true;
            },
            mouseleave: function() {
                isPause = false;
            }
        })
            
        function startProgressbar() {
            resetProgressbar();
            percentTime = 0;
            isPause = false;
            tick = setInterval(interval, 1);
        }
        
        function interval() {
            if(isPause === false) {
                percentTime += 1 / (time + .05);
                // console.log(percentTime);
                bar.css({
                    width: percentTime+'%'
                });

                if(percentTime >= 100)
                {
                    resetProgressbar();
                }
            }
        }
        
        function resetProgressbar() {
            bar.css({
                width: 0+'%'
            });

            clearTimeout(tick);
        }

        this.inner.on('afterChange', function(event, slick, direction){
            startProgressbar();
        });
    }
}
