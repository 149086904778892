import { BaseWidget } from './base-widget';

export class Anchor extends BaseWidget {
    static NAME = 'anchor';

    constructor(element) {
        super(element);
    }

    init() {
        this.element.on('click', (e) => {
            this.scrollTo();
        });
    }

    scrollTo() {
        this.anchor = this.element.data('anchor');
        const scrollToElement = $(`[name="${this.anchor}"]`)

        console.log(this.anchor);
        if (scrollToElement) {
            const headerHeight = $('.header').css('max-height').slice(0, -2);

            $('html, body').animate({
                scrollTop: scrollToElement.offset().top - headerHeight
            }, 1000);
        }
    }
}
