import { throttle } from 'lodash';
import { BaseWidget } from './base-widget';

export class Blog extends BaseWidget {
    static NAME = 'blog';
    // categoryButton: JQuery;
    // ajaxurl : string = '/wp/wp-admin/admin-ajax.php';
    // siteUrl;

    constructor(element) {
        super(element);            
        this.categoryButton = $('[data-blog-button]', this.element);
        this.siteUrl = this.element.data('site');
        this.ajaxurl = '/wp/wp-admin/admin-ajax.php';
    }

    init() {
        this.categoryButton.on('click', (e) => {
            $('.c-loading-bar').addClass('is-loading' );
            this.categoryButton.removeClass('is-active');
            $(event.target).addClass('is-active' );

            let data = {
                action: 'more_posts',
                // pagination: this.element.data('pagination'),
                // limit: this.element.data('limit'),
                // count: $(event.target).data('count'),
                cat: $(event.target).data('cat')
            }

            $.post((this.siteUrl + this.ajaxurl), data, function (response) 
            {}).then(function(response){
                $('[data-blog-section]').html(response);
                console.log(response);
            }).done(function () {
                $('[data-loading-bar]').removeClass('is-loading' );
                console.log('done');
            }).fail(function (exception) {
                $('[data-loading-bar]').removeClass('is-loading' );
                console.log('fail', exception);
            });
        });
    }
}
