import { BaseWidget } from './base-widget';

export class ScrollTo extends BaseWidget {
    static NAME = 'scroll-to';

    constructor(element) {
        super(element);
    }

    init() {
        this.deepLink = location.hash;

        if (this.deepLink) {
            this.scrollTo();
        }
    }

    scrollTo() {
        this.hash = this.deepLink.substr(1).replace(/^#/, '');
        const scrollToElement = $(`[name="${this.hash}"]`)

        if (scrollToElement) {
            const headerHeight = $('.header').css('max-height').slice(0, -2);

            $('html, body').animate({
                scrollTop: scrollToElement.offset().top - headerHeight
            }, 0);
        }
    }
}
