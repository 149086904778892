import { PopoverService } from './helpers/popover-service';

import { BaseWidget } from './base-widget';

export class Gallery extends BaseWidget {
    static NAME = 'gallery';

    constructor(element) {
        super(element);

        this.galleryURL = this.element.data('gallery');
        this.openElem = $('[data-gallery-open]', this.element);
    }

    init() {
        if (!this.galleryURL) {
            console.error('no youtube-id set for video');
            return;
        }

        this.openElem.on('click', e => {
           this.play();
        });
    }

    play() {

        const html = '<div class="media media--image"><div><figure><img src="' + this.galleryURL + '"></figure></div></div>';

        const options = {
            closeable: true,
            cssClass: false,
            onInsert: (overlay) => {
                this.youtubePlayer = this.createYoutubePlayer(overlay);
            },
            onClose: (overlay) => {
                this.close(overlay);
                return false;
            }
        };

        PopoverService.spawnOverlay(html, options);
    }

    close(overlay) {
        $('.media--video', overlay).removeClass('is-loaded');

        requestAnimationFrame(() => {
            window.setTimeout(() => {
                this.destroyYoutubePlayer();
            }, 200);
        });
    }

    createYoutubePlayer(overlay) {
        const self = this; // save this because of context YTPlayer
        const videoElem = $('.media > div', overlay)[0];
    }

    destroyYoutubePlayer() {
        if (this.youtubePlayer) {
            this.youtubePlayer.destroy();
            this.youtubePlayer = undefined;
        }
    }
}
