import { BaseWidget } from './base-widget';

export class Cursor extends BaseWidget {
    static NAME = 'cursor';

    constructor(element) {
        super(element);
    }

    init() {;

		if (!('ontouchstart' in window || 'ontouch' in window)) {
			// console.log('cursor')
			// $('body').addClass('no-touch');

			// $(function () {
			// 	const cursor = document.querySelector('.cursor');

			// 	document.addEventListener('mousemove', function (evt) {
			// 		const target = evt.target;
			// 		const toHover = target.closest('[data-cursor-hover]');

			// 		const x = evt.clientX;
			// 		const y = evt.clientY;
			// 		cursor.style['transform'] = `translate3d(${x}px, ${y}px, 0)`;
			// 		// cursor.style['transform'] = `matrix(1, 0, 0, 1, ${x}px, ${y}px, 0)`;

			// 		cursor.classList.toggle('cursor--grow', toHover);
			// 	});
			// });

			// $('body').mousedown(function () {
			// 	$('.cursor').addClass('active');
			// 	$('.cursor').addClass('clicked');
			// })

			// $('body').mouseup(function () {
			// 	$('.cursor').removeClass('clicked');
			// })
		}
    }
}
