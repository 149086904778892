import { BaseWidget } from './base-widget';

export class Footer extends BaseWidget {
    static NAME = 'footer-dropdown';

   constructor(element) {
        super(element);


        this.dropdownId = this.element.data('footer-dropdown');
        this.target = $(`[data-footer-target="${this.dropdownId}"]`);
    
        // this.target = $('.menu--container');
    }

    init() {
        this.element.on('click', (e) => {
            e.preventDefault();

            if (this.isOpen) {
                this.close();
            } else {
                this.open();
            }
        });
    }

    open() {
        this.isOpen = true;
        // this.element.addClass('is-open');

        console.log(this);
        this.element.addClass('is-open');
        this.target.addClass('is-open');
    }

    close() {
        this.isOpen = false;
        this.element.removeClass('is-open');
        this.target.removeClass('is-open');
    }
}
