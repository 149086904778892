import { BaseWidget } from './base-widget';
const lazysizes = require('lazysizes');

export class Lazyload extends BaseWidget {
    static NAME = 'lazyload';

    constructor(element) {
        super(element);
    }

    init() {
        lazysizes.init();
    }
}
