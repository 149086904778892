import { BaseWidget } from './base-widget';

export class Hover extends BaseWidget {
    static NAME = 'hover';

    constructor(element) {
        super(element);
        this.targetId = this.element.data('hover');
        this.targetIds = $(`[data-hover]`);
        this.target = $(`[data-hover-target="${this.targetId}"]`);
        this.targets = $(`[data-hover-target]`);
    }

    init() {
      this.element.on('mouseover', (e) => {
        this.show();
      });
    }
    

    show() {
      $(this.targets).removeClass('is-active');
      $(this.targetIds).removeClass('is-active');
      $(this.element).addClass('is-active');
      $(this.target).addClass('is-active');
    }
}
